@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

html {scroll-behavior: smooth;}
*{box-sizing: border-box;outline: none; transition: all .3s;-o-transition: all .3s;-webkit-transition: all .3s;-ms-transition: all .3s;}

body{font-size: 16px; font-family: 'Poppins', sans-serif;}
h1, h2, h3, h4 , h5, h6, strong{font-weight: 600;}
h1{font-size: 62px;line-height: 65px;font-weight: 700;margin-bottom: 20px;}
h2{font-size:45px;line-height: 55px;}
h3{}
h4{font-size:24px;}
h5{font-size:22px;text-transform: uppercase;}
h6{font-size:20px;text-transform: uppercase;margin-bottom: 18px;}
a{color: black;line-height: initial;}
a:hover{color:#088597; text-decoration:none}
p{font-size: 16px;font-weight: 300;color: rgba(37, 37, 37, 1);}
p:last-child{margin: 0;}
label{font-size: 14px;margin-bottom: 5px;text-transform: uppercase;}
img, iframe{max-width: 100%; vertical-align: middle;}
ul{padding-left: 16px;margin: 0;}
ul li{color: black;}
input, textarea, select{border-radius: 0;border: 1px solid rgb(199 199 199);font-size: 14px;height: initial;padding: 8px 10px;width: 100%;background: #fff}
input[type=submit], button{width:auto; border:0; cursor:pointer}
textarea{height:100px;}
video{width: 100%; vertical-align: middle;}
hr{width: 100%; float: left;}

.owl-nav{position: absolute;left: 0;top: 50%;width: 100%;}
.owl-nav button{}
.owl-nav button i{text-align: center;display: block;font-size: 40px;padding: 0px;transition: all .2s;}
.owl-nav button i:hover{color:red}
.owl-nav button.owl-prev{position: absolute;left: -40px;}
.owl-nav button.owl-next{position: absolute;right: -40px;}

.owl-dots{position: absolute;bottom: 10px;width: 100%;text-align: center;display: none;}
.owl-dots button{margin: 0 5px;}
.owl-dots button span{width: 14px;height: 14px;display: block;background: whitesmoke;border-radius: 5px;}
.owl-dots button.active{}
.owl-dots button.active span{background: #EB2125;}
.owl-dots button span:hover{background: #023575;}
.owl-carousel .owl-stage, .owl-carousel.owl-drag .owl-item{ -ms-touch-action: auto; touch-action: auto;}

.bg-light{background: rgba(232, 247, 249, 1)!important;}
.bg-dark{background: rgba(8, 133, 151, 1)!important;}
.bg-dark *{color:#fff}

.btn{padding: 10px 43px;font-size: 14px;font-weight: 600;}
.btn-dark{background:#000!important}
.btn-dark:hover{background: rgba(8, 133, 151, 1)!important;border-color: rgba(8, 133, 151, 1)!important;}

.btn-blue{background:rgba(8, 133, 151, 1)!important; color:#fff}
.btn-blue:hover{background: #000!important;border-color: rgba(8, 133, 151, 1)!important; color:#fff}

.btn-primary{background: rgba(8, 133, 151, 1)!important;border-color: rgba(8, 133, 151, 1)!important;}
.btn-primary:hover{background:#000!important}

.box-shadow{box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05)!important;}

header{}
header nav{}
header nav ul{margin: 0;padding: 0;list-style: none;}
header nav ul li{display: inline-block;vertical-align: middle;}
header nav ul li a{display: block;padding: 8px 22px;font-size: 15px;}
header nav ul li.active a, header nav ul li:hover a{color:#088597}

header .mobile-menu{cursor: pointer;position: relative;top: 9px;}
header .mobile-menu span{display: block;border-bottom: 4px solid #000000;width: 35px;border-radius: 10px;margin-bottom: 6px;position: relative;}	
header .mobile-menu span:nth-child(2){left: 0px;}
header .mobile-menu span:last-child{margin:0}
header .mobile-menu:hover span{border-color: #088597;}
header .mobile-menu.open{top:11px}
header .mobile-menu.open span:first-child{transform: rotate(-45deg);}
header .mobile-menu.open span:nth-child(2){transform: rotate(45deg);position: relative;top: -9px;}
header .mobile-menu.open span:nth-child(3){display:none}

.banner{ background: url('images/background.svg'); background-size: cover; background-position: center; }
.banner p{font-weight: 500;font-size:18px;}
.banner .btn{margin-top:20px;}
.banner:hover img{transform:scale(1.02)}
.banner .container:before{background: url('images/banner-bg.png');background-size: auto 100%;
    background-position: right;
    background-repeat: no-repeat;content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;}
.banner .container{padding: 8rem 15px!important; position: relative;}

.top-services{}
.top-services h2{margin: 0}
.top-services .box-shadow{border-radius: 20px!important;}
.top-services h4{margin: 15px 0;}
.top-services p{color: rgba(108, 117, 125, 1);}
.top-services .col-md-3:hover img{transform: scale(1.05);}

.about{background: rgba(251, 254, 255, 1); line-height: 1.8;}
.about a{font-weight: 600}

.whychoose h2{margin:0}
.whychoose img{float: left;margin-bottom: 20px;height: 70px;margin-right: 20px;}
.whychoose h4{}
.whychoose p{clear:both;color: rgba(108, 117, 125, 1);}
.whychoose .bg-white{border-radius:10px;}
.whychoose .bg-white:hover{background:#088597!important}
.whychoose .bg-white:hover *{color:#fff}
.whychoose .bg-white:hover img{transform: scale(1.05);}

.process{}
.process img{height: 50px;}
.process h5{margin: 0;}
.process .bg-white{display: inline-block;padding: 10px;width: 100px;height: 100px;border-radius: 50%;line-height: 5;margin-bottom: 25px;}
.process .col-md-3:hover img{transform: rotate(360deg);}
.process .arrow:after{content:'';background: url('images/arrow.svg');display: block;position: absolute;right: -40px;top: 40px;width: 75px;height: 30px;background-size: cover;}

footer{background:black}
footer *{color:#fff}
footer .subscribe{position: relative;}
footer .subscribe input[type="text"]{border: 1px solid #fff;background: initial;color: #fff;height: initial;padding: 6px 60px 6px 15px;font-weight: 400;}
footer .subscribe input[type="submit"]{position: absolute;right: 15px;top: 0px;background: initial;color: #fff;text-transform: uppercase;font-size: 14px;padding: 0;}
footer .subscribe input[type="submit"]:hover {color:#088597}
footer h4{}
footer ul{padding: 0;}
footer ul li{margin-bottom: 5px;}
footer a, footer p{font-size: 14px;font-weight: 400;margin: 0;}
footer ul li:last-child{margin:0}

footer .bottom p{color: rgba(255, 255, 255, 0.3);}
footer .border-top{border-top: 1px solid rgba(45, 45, 45, 1)!important;}
footer .border-right{border-right: 1px solid rgba(45, 45, 45, 1)!important;min-height: 200px;}

.social{}
.social a{font-size: 20px;margin-right: 12px;}
.social a:last-child{margin:0}
.social a:hover i{color: #088597;}

header .mobile-window.open{display: block!important;position: fixed;top: 0;left: 0;width: 100%;height: 100%;background: #088597;z-index: 99;}
header .mobile-window.open .fa-times{font-size: 30px;}
header .mobile-window.open *{color:#fff;}
/* header .mobile-window.open ul{} */
header .mobile-window.open ul li{display:block}
header .mobile-window.open ul li a{padding: 4px 0px;font-weight: 400;font-size: 20px;}
header .mobile-window.open img{filter: drop-shadow(2px 4px 6px black);}
header .mobile-window.open .social{text-align: left;}

@media(max-width:1200px){

}

@media(max-width:992px){
    .process h5{font-size: 18px;}	
    .process .arrow:after{width: 55px;height: 22px;right: -30px;}
    .banner .container:before{opacity: 0.3;}
}

@media(max-width:767px){
    h1{font-size: 52px;line-height: 55px;}
    h2{font-size: 30px;line-height: 40px;}
    /*h3{font-size:}*/
    h4{font-size: 20px;}
    h5{font-size: 18px;}
    h6{font-size: 15px;}
    footer .row .col-md-3:nth-child(2) .border-right{border-right: 0!important}
    footer .border-right{min-height: initial;}
    .process .arrow:nth-child(3):after{display:none}
    .btn{padding: 8px 20px;}
}

@media(max-width:575px){
    
}

.pendingBar{background: rgb(240, 191, 191); padding: 10px 0; border: 1px solid #e6474a; font-size: 12px; color: #EB2125;}