.App {
  text-align: center;
}

.App-logo {
  height: 100px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-100 {
  text-align: center;
  display: flex;
  height: 100vh;
  align-items: stretch;
}
.section-50 {
  width: 50%;
}

.section-50:first-child {
  border-right: 1px solid #CCC;
  background: #20AEC2;
}

.vAlignMiddle {
  text-align: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.vAlignMiddle > div {
  width: 100%;
}

.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
}

.form-container > div {
  margin-bottom: 20px;
}

.form-container input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  box-sizing: border-box;
}

.form-container button {
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  background: #088597;
  border: none;
  color: #FFF;
  font-size: 16px;
  cursor: pointer;
}

.form-container button:disabled,
.form-container button[disabled]{
  opacity: 0.5;
  cursor: auto;
}

.form-error{text-align: left; color: #b60606; font-size: 12px;}

.userDetail{padding: 40px 0;}

.course-container{padding: 40px 0;}

.loaderContainer{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.5);
}
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
}
  .loader:before,
  .loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .loader:after {
    color: #000;
    transform: rotateY(70deg);
    animation-delay: .4s;
  }

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

.notification{
  background: #ccc;
  font-size: 14px;
  text-align: left;
  padding: 5px 10px;
}

.notification.error {
  background: #ecd4d4;
}

.notification.success {
  background: #dbe8cc;
}